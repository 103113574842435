import React from "react";
import Countdown from "react-countdown";
import "../styles/Countdown.css";

const CountdownTimer = () => {
  // Function to calculate the next 12 PM UTC
  const getNextLaunchTime = () => {
    const now = new Date();
    const nextLaunch = new Date();
    
    // Set to 12 PM UTC
    nextLaunch.setUTCHours(12, 0, 0, 0);
    
    // If it's already past 12 PM UTC today, set the launch to tomorrow
    if (now > nextLaunch) {
      nextLaunch.setUTCDate(nextLaunch.getUTCDate() + 1);
    }
    
    return nextLaunch;
  };

  return (
    <div className="text-center my-4">
      <h2>Next Deals Launch In:</h2>
      <Countdown
        date={getNextLaunchTime()}
        renderer={({ hours, minutes, seconds, completed }) => {
          if (completed) {
            // Render a message when countdown is completed
            return <span className="countdown-completed">Deals are live!</span>;
          } else {
            // Render the countdown timer
            return (
              <span className="countdown-timer">
                {hours}h {minutes}m {seconds}s
              </span>
            );
          }
        }}
      />
    </div>
  );
};

export default CountdownTimer;

