import React, { useState, useEffect } from "react";
import { db } from "../firebase"; // Firebase Firestore setup
import { collection, onSnapshot } from "firebase/firestore";
import "../styles/HomePage.css";
import CountdownTimer from "../components/Countdown";
import Header from "../components/Header";

const HomePage = () => {
  const [apps, setApps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAppLive, setIsAppLive] = useState(false);

  useEffect(() => {
    const dailyDealsCollection = collection(db, "dailyDeals");

    const unsubscribe = onSnapshot(
      dailyDealsCollection,
      (snapshot) => {
        if (!snapshot.empty) {
          const appsData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setApps(appsData);
          setIsAppLive(true); // Mark the app as live when deals are found
        } else {
          setApps([]);
          setIsAppLive(false); // Mark the app as inactive when no deals are available
        }
        setLoading(false); // Stop loading regardless of the result
      },
      (error) => {
        console.error("Error fetching daily deals:", error);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  const sortedApps = apps.sort((a, b) => {
    if (a.isFree && !b.isFree) return -1;
    if (!a.isFree && b.isFree) return 1;
    if (a.isFree && b.isFree) {
      return a.name.localeCompare(b.name);
    }
    const discountA = a.originalPrice - a.discountedPrice;
    const discountB = b.originalPrice - b.discountedPrice;
    return discountB - discountA;
  });

  return (
    <div className="home-page-container">
      <Header />
      <div className="main-content">
        {loading ? (
          <div className="loading text-center">Loading...</div>
        ) : isAppLive ? (
          <>
            <div className="container mb-2">
              <CountdownTimer />
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-5 g-4">
                {sortedApps.map((app) => (
                  <div key={app.id} className="col">
                    <div className="card h-100">
                      <div className="card-header d-flex align-items-center">
                        <img
                          src={app.logoUrl}
                          className="app-logo"
                          alt={`${app.name} logo`}
                        />
                        <div className="ms-3">
                          <h5 className="card-title">{app.name}</h5>
                        </div>
                      </div>
                      <div className="card-body">
                        <p className="card-text h-50">{app.description}</p>
                        <div className="pricing-section">
                          <p className="original-price me-2 mb-0">
                            <del>${app.originalPrice}</del>
                          </p>
                          <p className="discounted-price mb-0">
                            {app.isFree ? "Free" : `$${app.discountedPrice}`}
                          </p>
                        </div>
                        {app.dealType === "lifetime" && (
                          <span className="deal-label lifetime-label mb-2">
                            Lifetime Subscription
                          </span>
                        )}
                      </div>
                      <div className="card-footer text-center">
                        <a
                          href={app.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="visit-button"
                        >
                          Visit App
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <div className="coming-soon text-center">
            <h1>We Are Launching Soon!</h1>
            <p>Stay tuned and subscribe to our newsletter to be the first to know!</p>
            <div id="mc_embed_shell">
              <link
                href="//cdn-images.mailchimp.com/embedcode/classic-061523.css"
                rel="stylesheet"
                type="text/css"
              />
              <div id="mc_embed_signup">
                <form
                  action="https://10appdeals.us7.list-manage.com/subscribe/post?u=e474e662786717257f0a8e29a&amp;id=68f652fdf6&amp;f_id=009382e0f0"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  className="validate"
                  target="_blank"
                >
                  <div id="mc_embed_signup_scroll">
                    <div className="mc-field-group">
                      <label htmlFor="mce-EMAIL">
                        Email Address <span className="asterisk">*</span>
                      </label>
                      <input
                        type="email"
                        name="EMAIL"
                        className="required email"
                        id="mce-EMAIL"
                        required
                      />
                    </div>
                    <div id="mce-responses" className="clear foot">
                      <div
                        className="response"
                        id="mce-error-response"
                        style={{ display: "none" }}
                      ></div>
                      <div
                        className="response"
                        id="mce-success-response"
                        style={{ display: "none" }}
                      ></div>
                    </div>
                    <div
                      aria-hidden="true"
                      style={{ position: "absolute", left: "-5000px" }}
                    >
                      <input
                        type="text"
                        name="b_e474e662786717257f0a8e29a_68f652fdf6"
                        tabIndex="-1"
                      />
                    </div>
                    <div className="optionalParent">
                      <div className="clear foot">
                        <input
                          type="submit"
                          name="subscribe"
                          id="mc-embedded-subscribe"
                          className="button"
                          value="Subscribe"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomePage;


// Simulate testing live or not-live scenarios
      // setTimeout(() => {
      //   setIsAppLive(true); // Set to false to test "Coming Soon" state
      //   setLoading(false); // Simulate loading finished
      // }, 1000); // Delay for testing purposes
