import React, { useState, useEffect } from "react";
import { db } from "../firebase"; // Firebase Firestore setup
import { collectionGroup, getDocs } from "firebase/firestore";
import "../styles/WallPage.css";
import "../styles/header.css";

const WallPage = () => {
  const [wallData, setWallData] = useState([]);
  const [loading, setLoading] = useState(true);

  const monthMapping = {
    jan: "January",
    feb: "February",
    mar: "March",
    apr: "April",
    may: "May",
    jun: "June",
    jul: "July",
    aug: "August",
    sep: "September",
    oct: "October",
    nov: "November",
    dec: "December",
  };

  const formatMonthYear = (monthYear) => {
    const [month, year] = monthYear.split("-");
    const fullMonth = monthMapping[month.toLowerCase()] || month;
    return `${fullMonth} ${year}`;
  };

  useEffect(() => {
    const fetchWallOfApps = async () => {
      try {
        const appsCollectionGroup = collectionGroup(db, "apps");
        const appsSnapshot = await getDocs(appsCollectionGroup);

        if (!appsSnapshot.empty) {
          const groupedData = {};

          appsSnapshot.forEach((doc) => {
            const parentPath = doc.ref.parent.parent.path;
            const monthYear = parentPath.split("/")[1];

            if (!groupedData[monthYear]) {
              groupedData[monthYear] = [];
            }

            groupedData[monthYear].push({
              id: doc.id,
              ...doc.data(),
            });
          });

          const formattedData = Object.keys(groupedData).map((key) => ({
            id: key,
            displayName: formatMonthYear(key),
            apps: groupedData[key],
          }));

          setWallData(formattedData);
        } else {
          console.log("No apps found.");
        }
      } catch (error) {
        console.error("Error fetching wallOfApps data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchWallOfApps();
  }, []);

  return (
    <div className="wall-page">
      <header className="header">
        <h2>Check out our featured apps from previous months!</h2>
      </header>
      <div className="container wall-content">
        {loading ? (
          <div className="text-center my-4">Loading...</div>
        ) : wallData.length > 0 ? (
          wallData.map((monthYear) => (
            <section key={monthYear.id} className="month-year-section">
              <h2 className="month-year-title">{monthYear.displayName}</h2>
              <div className="app-wall">
                {monthYear.apps.map((app) => (
                  <a
                    key={app.id}
                    href={app.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="app-link"
                  >
                    <img
                      src={app.logoUrl}
                      alt={`${app.name} logo`}
                      className="app-logo"
                    />
                  </a>
                ))}
              </div>
            </section>
          ))
        ) : (
          <div className="no-apps-message">
            <h2>No apps available right now.</h2>
            <p>Check back later to see the latest updates on the Wall of Apps!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default WallPage;
