import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDzV_obYDMXube5ZkqvO_xrVtC6ensOoZ4",
  authDomain: "appdeals-d47f2.firebaseapp.com",
  projectId: "appdeals-d47f2",
  storageBucket: "appdeals-d47f2.firebasestorage.app",
  messagingSenderId: "423755316067",
  appId: "1:423755316067:web:0a51ef3b3521b47be77156"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };
