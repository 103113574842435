import React from "react";
import "../styles/header.css";

const Header = () => {
  return (
    <header className="header">
      <h2>Discover amazing deals on iOS apps every day!</h2>
    </header>
  );
};

export default Header;
