import React from "react";

import "../styles/SubmitPage.css";
import "../styles/header.css";

function SubmitPage() {
  return (
    <div className="submit-page">
      <header className="header">
      <h2>Submit Your App</h2>
      </header>

      <main className="submit-content">
        <div className="top-section m-4">
          <section className="about-deals">
            <h2>Why I Developed 10AppDeals?</h2>
            <p>
              As a developer myself, I know that even the best apps can go unnoticed without proper visibility. Many platforms overwhelm users with countless options, but 10AppDeals is different.
            </p>
            <p>
              We feature a maximum of 10 apps each day, ensuring every app gets maximum visibility and promotion. This focused approach helps users discover deals easily and allows app creators to fully benefit from the exposure.
            </p>
          </section>
          <section className="benefits">
            <h2>Why Feature Your App on 10AppDeals?</h2>
            <p>Here’s what you gain by featuring your app:</p>
            <ul>
              <li>Enhanced visibility with a focused audience</li>
              <li>Increased downloads and user engagement</li>
              <li>A unique opportunity to showcase your app’s value</li>
            </ul>
          </section>
        </div>
        <section className="submission-guidelines m-4">
          <h2>How to Get Featured?</h2>
          <p>
            Want your app to be featured? It’s easy! Just email us at{" "}
            <a href="mailto:deals@10appdeals.com">deals@10appdeals.com</a> with the subject line <strong>"App Promotion"</strong>. 
          </p>
          <p>
            Once we receive your email, we’ll send you a form to fill out with the details of your app and any special promotions or deals you’d like to highlight. Submit the form, and we’ll take care of the rest!
          </p>
        </section>
      </main>
    </div>
  );
}

export default SubmitPage;
